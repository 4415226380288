import { getProductList } from "../components/api";
import Step from "@/components/global/Step/Step.vue";
import ProductListNew from "../components/ProductListNew.vue";
import ProductModal from "../components/ProductModal.vue";
import axios from "axios";
import { dmsPublicPath } from "@/common/constant.js";
import {
  // 分销商 分销地址
  showAnother,
  // 收货地址切换带出联系人
  getDISAdd,
  // 分销商地址切换请求联系人
  getPhone,
  findBaseData,
  getBasicMessage, //零售订单基础信息
  // 零售订单下单
  retailOrder,
  // 获取销售组织list
  getOrgList,
  findMaterialByStore,
  getSeverList,
  getWarehouseList,
   findSalesOrderDetail, createXxmRetailOrder
} from "./api";
import moment from "moment";
import { mapState } from "vuex";
import { getAdressapi } from "@/views/settlement/api";
import addUserSource from "@/views/userOperations/IntendedUserEntry/components/addUserSource";
import addUserTips from "@/views/userOperations/IntendedUserEntry/components/addUserTips";
import { findList, findListLabel, saveInfo, saveInfoLabel } from "@/views/userOperations/IntendedUserEntry/api";
import { getDeliveryAmountFind } from "@/views/stockCenter/adjustmentApply/allot/api";
import { getUserList } from "@/views/userOperations/IntentionList/api";
import {
  getTableList,
  sysconfig
} from "@/views/order/channelExamine/api";
import { ctsApi, fxZsyhGetDeliveryMethod, isBrightPilotRegion } from "@/views/channel/api";
import { findMoneyBuyBegin } from "@/views/specialZone/api";
import { checkRetailL } from "@/views/channelInput/saleNew/api";

export default {
  name: "Home",
  components: {
    Step,
    ProductListNew,
    ProductModal,
    addUserSource,
    addUserTips,
  },
  // props: {
  //   orderType: {
  //     type: String,
  //     required: true
  //   }
  // },
  data() {
    return {
      sexoptions:[
        {
          id:1,
          name:'男士'
        },
        {
          id:2,
          name:'女士'
        }
      ],
      rowSelectionType: "checkbox", //是否编辑
      selRow: {}, //当前编辑的行
      modelShow: false,
      bankChecked: true,
      visible: false,
      steps: ["录入订单", "确认订单信息", "提交订单"],
      currentStep: 2,
      deliveryOptions: [],
      prods: [],
      // 开票户头账号
      choseAccounts: [],
      // 开票户头id
      invoiceId: "",
      //是否允许提前发货
      allowAdvance: true,
      // 用户选择截止时间
      // endTime: '',
      // 经销商下拉选择框
      distr: false,
      // 经销商地址
      distrAddress: "",
      distrAdd: false,
      distrName: "",
      // 经销商地址
      orderDis: [],
      // 选择直配到分销时填写客户单号
      orderNumber: "",
      // 用户详细地址
      userDetailAddress: "",

      // 省市区三级联动
      secondCity: "",
      // 接收子组件选中的商品
      sonChectItme: [],
      receiveAdd: false,
      // 收货地址/统仓地址
      receive: "",
      // 销售组织id
      receiveID: "",
      // 收货地址类型 obj.shareFlag=='Y'?'统仓地址':'收货地址'
      // 配送方式ID
      sendId: "",
      // 分销商地址选择
      distributionAddress: [],
      // 输入输入内容
      searchInt: "",
      // 省
      provices: [],
      //市
      getCity: [],
      // 城镇
      getTown: [],
      // 城镇下一级
      qu: [],
      // 省市区选择
      proObj: {},

      // 经销商地址列表
      choseDistribution: [],
      // 分销商列表
      DisList: [],
      // 经销商及经销商地址
      distributionAdd: {
        DISCODE: "",
        DISADD: ""
      },
      // 经销商切换获取地址 接口参数
      DISADDPARAMS: {
        customerId: "",
        orgId: "",
        matklId: ""
      },
      // 要求到货周
      arriveOptions: [],
      selected: "",
      // 商品数量、体积、价格、预付款
      goodsNum: 0,
      goodsVolume: 0,
      goodsPrice: 0,
      advancePrice: 0,
      TotalPayment: {},

      //返利 （返利选项是否选中）
      //返利按钮是否选中
      isTradeChecked: true,
      // 预付比例
      advancePayRate: 0,
      totalAdvanceMoney: 0,
      drawerName: "",
      dissize: false,
      // 点击提交按钮提交的参数
      orgAndGroup: "",
      orderCartIds: {},
      // 配送方式
      trans: "",
      hisenseOff: true,
      officehis: [],
      officeCode: "",
      receivehis: "",
      officeHis: false,
      orgCode: "",
      orgName:'',
      matklId: "",
      orgId: "",
      orgtitle: "",
      obj: {},
      // 提交给后台订单详情
      submitGoods: [],
      endTime: "",
      // 有效截止时间
      startTime: "",
      // 后台接口最大截止时间
      maxEndDate: "",
      // 面包屑
      breadcrumbData: [
        {
          path: "/index",
          name: "index",
          title: "首页"
        },
        // {
        //   path: "/cart",
        //   name: "cart",
        //   title: "订单录入"
        // },
        {
          path: "/",
          name: "",
          title: "零售订单录入"
        }
      ],
      // 按钮组件loading
      isLoading: false,
      // 页面加载loading
      pageLoadFlag: false,
      //备注
      BZvalue: "",
      // 收货地址
      shippingAddress: {},
      totalOrderedQty: 0,
      totalAmount: 0,
      purchaseOrderItem: [],

      supplierCode: "",
      // 渠道客户
      business: {
        code: "",
        name: ""
      },
      // 基础信息接口数据
      realObj: {},
      // 销售门店
      XSMD: [],
      receiveAddXS: false,
      receiveXS: "",
      receiveXSID: "",
      // 发货仓库
      receiveFH: [],
      receiveAddFH: false,
      receiveFHS: "",
      receiveFHID: "",
      // 收货地址
      receivehisSH: "",
      // 销售类型
      saleOptions: [
        {
          name: "零售",
          id: "retail"
        },
        {
          name: "工程",
          id: "engineering"
        }
      ],
      XSLX: "",
      kpf: "",
      productList: [],
      dataCache: [],
      //组件
      columns: [{
        title: '仓库',
        dataIndex: 'warehouseName',
        width: 230,
        ellipsis: true
      },
        {
          title: '产品型号',
          dataIndex: 'model',
          width: 180,
          ellipsis: true
        },
        {
          title: '质量等级',
          dataIndex: 'quvlitylv',
          width: 120,
        },
        {
          title: '补差类型',
          dataIndex: 'invStatusTypeName',
          width: 120
        },
        {
          title: '物料号',
          dataIndex: 'materialCode',
          width: 150,
        },
        {
          title: '库存价格（元）',
          dataIndex: 'price',
          width: 150,
        },
        {
          title: '定价组',
          dataIndex: 'priceGroupName',
          width: 140,
        },
        {
          title: '实际库存',
          dataIndex: 'totalBig',
          width: 100,
        },
        {
          title: '预留数量',
          dataIndex: 'bigQtyLock',
          width: 100,
        },
        {
          title: '可用数量',
          dataIndex: 'bigQty',
          width: 130,
        },
        {

          title: '本次出库数量',
          dataIndex: 'numberThis',
          scopedSlots: {
            customRender: "count"
          },
          fixed: 'right',
          width: 180,
        },
      ],
      // 表格数据
      tableData: [],
      dataList: [],
      isVisible: false,
      tabelIsLoading: false,
      searchIpt: "",
      tableTotal: 0,
      pager: {
        count: 0,
        pageNo: 1,
        pageSize: 10
      },
      // 城市
      checkCity: "",
      checkTownCode: "",
      checkXZ: "",
      sheng: "",
      materialGroup: [],
      xszhId: "",
      fhckId: "",
      fhckType: "",//仓库类型
      xsmdId: "",
      isEnableOrNot: true,
      TGOptions: [
        {
          name: "是",
          id: "1"
        },
        {
          name: "否",
          id: "0"
        }
      ],
      tgType: "",
      SZOptions: [
        {
          name: "是",
          id: "1"
        },
        {
          name: "否",
          id: "0"
        }
      ],
      szytType: "",
      fwlbId: [],
      // 选中的服务列表
      // serverBox:'',
      // 服务列表数据
      FWlist: [],
      fileList: [],
      ImgIds: [],// 上传后的id
      visibleshop: false,
      visibleshopGC: false,
      visibleshopLS: false,
      show1: false,
      show2: false,
      show3: false,
      show1Message: "",
      show2Message: "",
      show3Message: "",
      isOldNew: 0, // 是否依旧换新 0否 1是
      isCompleteOld: 0, // 是否完成收旧 0否 1是
      oldMachCategorys: [],// 旧机品类列表
      oldMachCategory: "",// 旧机品类
      oldMachCategoryName: "",
      oldMachTreatWays: [], // 旧机处理途径
      oldMachTreatWay: "",// 旧机处理途径
      oldMachTreatWayName: "",
      companyList: [],
      fetching: false,
      provinceId: "",
      cityId: "",
      districtId: "",
      adressObj: {},
      selectDisabled: false,
      sexId:1,// 性别
      userSorceList:[],
      visibleSorce:false,
      SorceIds:'',
      userTipsList:[],
      TipsIds:[],
      visibleTips:false,
      mdlx:'',
      addressHx:[],
      cherkStatus:false,
      visibleAAA:false,
      openCloseBtn:false,
      showTIP:false,
      msgStatus:'',
      smallLoanSwitch: false,
      showInfo:'',
      searchStr:'',
      proIndex:'',
      documentNum:'',
      recoveryModeShow: false,
      recoveryTxt:'',
      recoveryShowTxt:'',
      productListOld:0,
      isShowLogisticsIcon:false,// 是否展示问号表示
      serviceCharge:0,
      orderServiceFeeItemDtoList:[],
      warehouseInfoId:'',
      phone:'',
      text1:'',
      text2:'',
      haveDeliveryFee:false,
      transDefault:'',
      compassHouseTaskId:'',
      compassHouseTaskName:'',
      startShowTime:'',
      endOpen:false,
      orderDetail: {
        orderHeader: {
          documentNum: '订单编号',
          documentType: '订单类型',
          statusName: '订单状态',
          // canCancel: '123',
          orgName: '供应商',
          customerName: '客户',
          billToName: '结算单位',
          documentDate: '下单时间：',
          purchaseNum: '关联采购单号：',
          addressDesc: '收货地址明细',
          deliveryTypeTitleValue: '配送方式：',
          contact: '联系人：',
          contactInfo: '联系电话：',
          edt: '期望送货日期：',
          isAllowAdvancDeliver: '允许提前交货：',
          payType: '支付方式：',
          dataSource: '来源',
          customerCode: '下单账号',
          message: '订单来源：'
        }
      },
      // 接口数据备份
      NeworderDetail: {
        NeworderHeader: {}
      },
      searchLength: 0,
      selectedRows: [],
      chukuTotal: 0,
      chukuVolume: 0,
      serviceFee: 0,// 服务费
      paymentGoods: 0,// 货款合计

      daichuku: 0,
      // 全选
      selectedRowKeys: [],
      // 搜索用
      totalIndex: 0,
      isLoadingReturn: false,
      shareType: '',
      shareTypezong:'',// 总开关
      showTips:false,
      checkNumFlag: false,
      filterArr:[],
      serviceFeeTotal:0,
      type:'',
      sendObject: [],
      //表格搜索
      tableSearch: '',
      isBright:false,
    };
  },
  computed: {
    ...mapState({
      isFenxiaoOrder: state => state.user.userInfo.account.marketModels,
      toPath: state => state.user.toPath
    })
  },


  mounted() {
    this.getOrderListCK()
    //获取基本信息
    this.basicMessage();

    this.getLoanSwitch()
    this.getuserSorceList()
    this.getuserTipList()
    this.getisKQ()
    this.getywy();
    // 开票方
    this.getkpf();
    // 提示文字修改
    this.getMessage();
    this.getMessage1();
    this.getMessage2();
    this.getOldMachCategory();
    this.getOldMachTreatWays();
    const data1 = this.$getAlertInfoByCode('TS0248')
    const data2 = this.$getAlertInfoByCode('TS0249')
    this.text1 = data1.msgDesc.split('${phone}')
    this.text2 = data2.msgDesc
    this.phone = data1.remark


  },
  watch: {
    'this.orderDetail.orderHeader.salesOrderItem': {
      handler() {
        let YCnum = 0
        if (this.orderDetail.orderHeader&&this.orderDetail.orderHeader.salesOrderItem&& this.orderDetail.orderHeader.salesOrderItem.length > 0 && this.showTIP && this.smallLoanSwitch) {
          for(let i = 0; i < this.orderDetail.orderHeader.salesOrderItem.length;i++) {
            if(this.orderDetail.orderHeader.salesOrderItem[i] && this.orderDetail.orderHeader.salesOrderItem[i].gicWarehouseType && this.orderDetail.orderHeader.salesOrderItem[i].gicWarehouseType == 20) {
              YCnum+=1
            }
          }
          this.selectDisabled = true;
        } else {
          this.selectDisabled = false;
        }
        // 如果选择了统仓的 要展示并且默认
        if(YCnum>0 && this.showTIP && this.smallLoanSwitch) {
          this.openCloseBtn = true
          this.cherkStatus = true
        } else {
          this.openCloseBtn = false
          this.cherkStatus = false
        }
        if(this.orderDetail.orderHeader && this.orderDetail.orderHeader.salesOrderItem && this.orderDetail.orderHeader.salesOrderItem.length>0){
          if((this.trans == '04' || this.trans == '08') && this.proObj.zCode){
            this.getFWF()
          }
        }

      },
      immediate: true,
      deep: true,
    },
    // receiveID: {
    //   immediate: true,
    //   handler(newValue) {
    //     this.isEnableOrNotBuchaUpdate();
    //   }
    // }
    trans: {
      immediate: true,
      handler(newValue) {


      }
    },
    XSLX: {
      immediate: true,
      handler(newValue) {

      }
    },
    fhckId: {
      immediate: true,
      handler(newValue) {

      }
    }

  },
  methods: {
    handleGoProduct(){
      const params = {
        storeId: this.XSMD.find(item => item.value == this.xsmdId).key,
        storeName: this.xsmdId,
        userName: this.business.name,
        phone: this.business.code,
        gender: this.sexId,
        SorceIds: this.SorceIds,
        userLabel: this.userTipsList.filter(item => item.checked).map(item => item.id),
        userDetailAddress: this.userDetailAddress,
        addressHx: this.addressHx,
        proviceCode: this.proObj.proviceCode,
        cityCode: this.proObj.cityCode,
        townCode: this.proObj.townCode,
        zCode: this.proObj.zCode,
      }
      const { id, salesOrderItem, agentDealers, orgId } = this.orderDetail.orderHeader;

      localStorage.setItem("goProduct", JSON.stringify(params));
      this.$router.push({
        path: "/confirm/channel",
        query: {
          type:'orderList',
          productId: salesOrderItem[0].productCode,
          qty: salesOrderItem[0].borderedQty,
          orgId: orgId,
          policyId: '',
          agentDealers:JSON.stringify(agentDealers),
          versions: '',
          supplierName: agentDealers.length==1?`${agentDealers[0].orgName}-${agentDealers[0].dealerName}`:'',
          supplierId: agentDealers.length==1?agentDealers[0].orgId:'',
          pageUrl: 'detailPro',
          saleOrderId: id,
        }
      });
    },
    changePrice(item){
    },
    edit(item) {
      item.idEdit = !item.idEdit
    },
    blurPrice(item,e){
      item.salePrice=item.salePrice.replace(/[^\-?\d.]/g,'')
      item.salePrice= Number(item.salePrice).toFixed(2)
      if (item.type == 1 && item.salePrice) {
        if (Number(item.salePrice) < item.min || Number(item.salePrice) > item.max) {
          if (item.haveRetailL) {
            this.$message.warning(`请在默认建议零售价的${item.bei}倍范围内录入价格`);
          } else {
            this.$message.warning(`您录入的价格不符合产品公司规定，请按要求录入。`);
          }
          item.salePrice = item.retailPrice;
          this.$forceUpdate()
          return;
        }
      }
      this.$emit("changePrice", item);
    },
    getType(data) {
      return sysconfig(data)
    },
    getOrderListCK() {
      let dataCK = {
        cisCode: this.$store.state.user.userInfo.customer.cisCode,
        salesOrderId: this.$route.query.id
      }
      findSalesOrderDetail(dataCK).then(res => {
        var realData = JSON.parse(res.data);
        if (realData.code == '1') {
          this.$message.warning(realData.msg);
          return
        }
        realData.data.salesOrderItem.forEach(el=>{
          el.orgCode = realData.data.orgCode;
          el.orgName = realData.data.orgName;
          el.idEdit = false
          // checkRetailL({id:el.productCode}).then(resRetail => {
          //   if(resRetail.data && resRetail.data.code == 0){
          //     const {type, money, bei, min, max, haveRetailL } = resRetail.data;// 0不限制  1限制
          //     el.min = min;
          //     el.bei = bei;
          //     el.type = type;
          //     el.max = max;
          //     el.salePrice = this.$util.getFloat(Number(money),2)
          //     el.retailPrice = this.$util.getFloat(Number(money),2)
          //     el.haveRetailL = haveRetailL;
          //   }
          // })
        })
        var dataList = realData.data.salesOrderItem;
        this.orgCode = realData.data.orgCode;
        this.orgName = realData.data.orgName;
        var newArray = [];
        for (let i = 0; i < dataList.length; i++) {
          this.daichuku = this.daichuku + Number(dataList[i].backnowledgedQty);
          let datas = {
            cisCode: this.$store.state.user.userInfo.customer.cisCode,
            terms: {
              isFuzzy:false,
              zzprdmodel:dataList[i].zzprdmodel,
              code:dataList[i].productCode,
              orgCode: realData.data.orgId,
              model: dataList[i].model,
              invType: 110,
              invStatusId: dataList[i].invStatusId,
              materialCode:dataList[i].materialCode
            },
            page: {
              pageNo: 1,
              pageSize: 100
            }
          }
          this.pageLoadFlag = false;
          // this.getTable(data, i)
          // 查询商品库存
          let productCode = []
          productCode.push(dataList[i].productCode)
          newArray.push(getTableList(datas))
        };
        Promise.all(newArray).then(value => {
          for (let i = 0; i < value.length; i++) {
            // var newArrays = [];
            // 如果是gic预留  则可出库数量 == 预留数量
            // 非 gic预留仓库
            // 本次出库数量需要 小于 可出库数量 - 已出库数量
            var kechuku = 0;
            if (value[i].data) {
              var realValue = JSON.parse(value[i].data);
              realData.data.salesOrderItem[i].bencichuku = 0;
              realData.data.salesOrderItem[i].checkAll = false;
              realData.data.salesOrderItem[i].selectedRowKeys = [];
              for (let y = 0; y < realValue.data.length; y++) {
                realValue.data[y].index = i;
                realValue.data[y].price = realData.data.salesOrderItem[i].bprice;
                realValue.data[y].numberThis = 0;
                realValue.data[y].key = y;
                realValue.data[y].selectedTableRow = false;
                // 可用库存
                let jobQty = realValue.data[y].bigQty
                if (realValue.data[y].isShare == '1') {
                  realValue.data[y].bigQty = realValue.data[y].bigQtyLock;
                } else {
                  realValue.data[y].bigQty = Number(realValue.data[y].bigQty) - Number(dataList[i].shippedBqty);
                }
                // 如果来源是job 赋值可用库存
                if(realData.data && realData.data.dataSource == 'job') {
                  realValue.data[y].bigQty = Number(jobQty);
                }
                kechuku = Number(kechuku) + realValue.data[y].bigQty;
              }
              if(realValue.data.length > 0){
                realValue.data.forEach(el => {
                  el.isDisabled = false
                })
              }
              realData.data.salesOrderItem[i].searchLength = realValue.data.length;
              realData.data.salesOrderItem[i].tableList1 = realValue.data;
              realData.data.salesOrderItem[i].tableList = realValue.data;
              realData.data.salesOrderItem[i].kechuku = Number(kechuku);

            }
          }
        
          if(realData.data.salesOrderItem.length>0){
            realData.data.salesOrderItem.forEach(item=>{
              if(item.itemCouponModelList&&item.itemCouponModelList.length>0){
                item.itemCouponModelListBOTTOM = item.itemCouponModelList.filter(it=>it.source!=='海信爱家')
                item.itemCouponModelListline = item.itemCouponModelList.filter(it=>it.source=='海信爱家')
              }
            })
          }
          console.log('realData.data.salesOrderItem',realData.data.salesOrderItem)
          this.orderDetail.orderHeader = realData.data;
          this.NeworderDetail.NeworderHeader = JSON.parse(JSON.stringify(realData.data));
          this.getFxGetDeliveryMethod()
        })
      }).finally(() => {
        this.pageLoadFlag = false;
      })
    },
   handleinput(index, e) {
      let tota = 0
      this.orderDetail.orderHeader.salesOrderItem[index].tableList = this.NeworderDetail.NeworderHeader.salesOrderItem[index].tableList;
      for (var i = 0; i < this.NeworderDetail.NeworderHeader.salesOrderItem[index].tableList.length; i++) {
        tota += Number(this.NeworderDetail.NeworderHeader.salesOrderItem[index].tableList[i].bigQty)
      }
      this.orderDetail.orderHeader.salesOrderItem[index].kechuku = tota;
    },
    // 仅查看已选
    onlyShowCheckedChange(item, index, e) {
      if (e.target.checked) {
        this.orderDetail.orderHeader.salesOrderItem[index].tableList =
          this.orderDetail.orderHeader.salesOrderItem[index].tableList.filter(it=>it.numberThis>0)
      } else {
        this.orderDetail.orderHeader.salesOrderItem[index].tableList = this.NeworderDetail.NeworderHeader.salesOrderItem[index].tableList;
        // this.orderDetail.orderHeader.salesOrderItem[index].bencichuku = 0
      }
      this.orderDetail.orderHeader.salesOrderItem[index].checkAll = e.target.checked;
    },
    // 清空选择
    clearChose(index) {
      this.orderDetail.orderHeader.salesOrderItem[index].checkAll = false;
      this.orderDetail.orderHeader.salesOrderItem[index].bencichuku = 0
      this.orderDetail.orderHeader.salesOrderItem[index].tableList.forEach(item=>{
        item.numberThis = 0
      })
    },
    // 搜索仓库
    tableSearchClick(index) {
      var newarray = [];
      for (let item of this.orderDetail.orderHeader.salesOrderItem[index].tableList) {
        var indexok = item.warehouseName.indexOf(this.tableSearch)
        if (indexok != -1) {
          newarray.push(item);
        }
      }
      let tota = 0
      for (var i = 0; i < newarray.length; i++) {
        tota += Number(newarray[i].bigQty)
      }
      this.orderDetail.orderHeader.salesOrderItem[index].kechuku = tota;
      this.orderDetail.orderHeader.salesOrderItem[index].searchLength = newarray.length;
      this.orderDetail.orderHeader.salesOrderItem[index].tableList = newarray;
    },
    //出库数量改变
    inputNumberChangeCK(row,index,record) {
      if(row.numberThis > 0){
        row.selectedTableRow = true
      } else {
        row.selectedTableRow = false
      }
      let count  = 0
      let arr = this.orderDetail.orderHeader.salesOrderItem[row.index].tableList
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].numberThis > 0) {
          count += arr[i].numberThis;
        }
      }
      this.orderDetail.orderHeader.salesOrderItem[row.index].tableList.forEach((el)=>{
        if(count == 0 || el.numberThis > 0){
          el.isDisabled = false
        }  else {
          el.isDisabled = true
        }
      })

      var realNumber = this.orderDetail.orderHeader.salesOrderItem[row.index];
      // var realData = this.orderDetail.orderHeader.salesOrderItem;
      var chukuNumber = 0;
      for (let i = 0; i < realNumber.tableList.length; i++) {
        if(realNumber.tableList[i].selectedTableRow) {
          chukuNumber += Number(realNumber.tableList[i].numberThis);
        }
      }
      this.orderDetail.orderHeader.salesOrderItem[row.index].bencichuku = chukuNumber;
      this.NeworderDetail.NeworderHeader.salesOrderItem[index].tableList = this.orderDetail.orderHeader.salesOrderItem[index].tableList

      if((this.trans == '04' || this.trans == '08') && this.proObj.zCode){
        this.getFWF()
      }
      this.getFxGetDeliveryMethod()
    },
    getDetails(){
      let data = {
        cisCode: this.$store.state.user.userInfo.customer.cisCode,
        salesOrderId:this.$route.query.id
      }
      this.pageLoadFlag = true
      findSalesOrderDetail(data).then(res=>{
        let data;
        if (typeof res.data == "string") {
          data = JSON.parse(res.data);
        } else {
          data = res.data;
        }
        if(data.code == 0) {
          this.documentNum = data.data.documentNum
          this.xsmdId = data.data.storeName
          this.XSLX = data.data.retailType
          this.receiveXSID = String( data.data.storeCode)
          this.getMetarial()
          if (this.XSMD.length > 0) {
            let obj = this.XSMD.find((i) => {
              return i.key === this.receiveXSID;//筛选出匹配数据
            });
            this.mdlx = obj.isSpeclalShop
            this.shopId = obj.key
          }
          this.business.code = data.data.phone
          this.business.name = data.data.customerName
          this.trans = data.data.deliveryMode

          this.BZvalue = data.data.message
          this.fileList = data.data.attachmentList
          this.isOldNew = data.data.isOldNew
          if( this.isOldNew == 1){
            this.isCompleteOld =  data.data.isCompleteOld
            // this.oldMachCategory =  data.data.isCompleteOld
            this.oldMachTreatWay =  data.data.oldMachTreatWay
          }

          this.proObj.proviceCode = data.data.provinceId
          this.proObj.cityCode =  data.data.cityId
          this.proObj.townCode =  data.data.countyId
          this.proObj.zCode =  data.data.townId
          this.addressHx = [data.data.provinceId,data.data.cityId,data.data.countyId,data.data.townId]
          this.userDetailAddress = data.data.addressDesc
          this.sexId = data.data.customerGender

          // 如果后端返回回收方式 就展示
          this.recoveryTxt = data.data.recycleWay
          this.recoveryShowTxt= data.data.recycleWay==1?'门店回收':'有闲有品'
          if(this.recoveryTxt) {
            this.recoveryModeShow = true
            this.isOldNew = '1'
            this.isCompleteOld = '1'
            this.oldMachCategory =  data.data.oldMachCategory
            this.oldMachCategoryName =  data.data.oldMachCategoryName
          } else {
            this.isOldNew = '0'
            this.isCompleteOld = '0'
          }
          this.orderLabels = data.data.orderLabels
          if( this.business.code  && this.shopId){
            this.changePhoneNum()
          } else {
            // 如果当前来源是用户录入 就默认给赋值旧机换新
            if(this.orderLabels == '用户录入'){
              this.userTipsList.forEach(item => {
                if(item.id == 5007820){
                  item.checked = true;
                  this.TipsIds.push(item.id )
                }
              });
            }
          }

          this.pageLoadFlag = false
        } else {
          // this.$message.warning('获取详情失败')
          this.pageLoadFlag = false
        }
      }).catch(error=>{
        this.pageLoadFlag = false
      })
    },
    // 获取小贷总开关
    getLoanSwitch() {
      sysconfig({key:'QD_OPEN_SMALL_LOANS'}).then(res => {
        this.smallLoanSwitch = res.data.data == 'Y' ? true : false
      })
    },
    changePhoneNum() {
      if(this.userTipsList.length>0){
        this.userTipsList.forEach(item=>{
          item.checked = false
          this.TipsIds=[]
        })
      }
      this.business.code =  this.business.code.replace(/\D/g, '')
      if(this.business.code.trim().length == 11){
        let data = {
          phone: this.business.code,
          shopInfoId:this.shopId
        }
        getUserList(data).then(res=>{
          if(res.data.code == 0 && res.data.list && res.data.list.length > 0) {
            if(!this.business.name){
              this.business.name =  res.data.list[0].userName
            }
            this.SorceIds =  res.data.list[0].sourceId
            this.compassHouseTaskId = res.data.list[0].compassHouseTaskId?res.data.list[0].compassHouseTaskId:''
            this.compassHouseTaskName = res.data.list[0].compassHouseTaskName?res.data.list[0].compassHouseTaskName:''
            this.sexId = res.data.list[0].genderName=='男' ? 1 : (res.data.list[0].genderName=='女'? 2 : 1 )

            if( res.data.list[0].userLableList.length>0 && this.userTipsList.length > 0){
              this.userTipsList.forEach(item=>{
                res.data.list[0].userLableList.forEach(twoItem=>{
                  if(item.id == twoItem.labelId) {
                    item.checked = true
                    this.TipsIds.push(twoItem.labelId)
                  }
                })
              })
            }else {
              this.TipsIds=[]
              this.TipsNames=[]
              const { salesOrderItem } = this.orderDetail.orderHeader;
              if(salesOrderItem && salesOrderItem.length > 0 && salesOrderItem[0].itemCouponModelList && salesOrderItem[0].itemCouponModelList.filter(item => item.source == "海信爱家").length > 0){
                this.SorceIds = 5007687;
                this.TipsIds = []
                this.userTipsList.forEach(item=>{
                  if(item.id == 5007820) {
                    item.checked = true
                    this.TipsIds.push(item.labelId)
                    this.TipsNames.push(item.label);
                  }else{
                    item.checked = false
                  }
                })
                this.$forceUpdate()
              }else{
                this.SorceIds = "";
                // this.sexId = 1
                this.TipsIds=[]
                this.TipsNames=[]
              }
              // this.$nextTick(()=>{
              //   if(this.userTipsList.length>0){
              //     this.userTipsList.forEach(item=>{
              //       item.checked = false
              //       if(item.id  == 5007817){
              //         item.checked = true
              //         this.TipsIds.push(item.id)
              //         this.TipsNames.push(item.label);
              //       }
              //     })
              //   }
              // })
            }

          }else {
            // this.SorceIds = 1
            // this.proObj.proviceCode = ''
            // this.proObj.cityCode = ''
            // this.proObj.townCode = ''
            // this.proObj.zCode = ''
            // this.userDetailAddress = ''
            this.sexId = 1
            this.compassHouseTaskId = ''
            this.compassHouseTaskName = ''
            // this.addressHx = []
            // 如果当前来源是用户录入 就默认给赋值旧机换新
            // if(this.orderLabels == '用户录入'){
            //   this.userTipsList.forEach(item => {
            //     if(item.id == 5007820){
            //       item.checked = true;
            //       this.TipsIds.push(item.id )
            //     }
            //   });
            // }
            this.TipsIds=[]
            this.TipsNames=[]
            const { salesOrderItem } = this.orderDetail.orderHeader;
            if(salesOrderItem && salesOrderItem.length > 0 && salesOrderItem[0].itemCouponModelList && salesOrderItem[0].itemCouponModelList.filter(item => item.source == "海信爱家").length > 0){
              this.SorceIds = 5007687;
              this.TipsIds = []
              this.userTipsList.forEach(item=>{
                if(item.id == 5007820) {
                  item.checked = true
                  this.TipsIds.push(item.labelId)
                  this.TipsNames.push(item.label);
                }else{
                  item.checked = false
                }
              })
              this.$forceUpdate()
            }else{
              this.SorceIds = "";
              // this.sexId = 1
              this.TipsIds=[]
              this.TipsNames=[]
            }
            // this.$nextTick(()=>{
            //   if(this.userTipsList.length>0){
            //     this.userTipsList.forEach(item=>{
            //       item.checked = false
            //       if(item.id  == 5007817){
            //         item.checked = true
            //         this.TipsIds.push(item.id)
            //         this.TipsNames.push(item.label);
            //       }
            //     })
            //   }
            // })
          }
        })
      }

    },


    changeStatus(){
      this.cherkStatus = !this.cherkStatus
      this.$forceUpdate()
    },
    onAddressChange(addressValArr) {
      let oldAdress = this.proObj.zCode
      // 长度如果等于2 乡镇就置空
      if(addressValArr&&addressValArr.length == 2) {
        this.checkTownCode = '';
        this.proObj.townCode = ''
      }
      // 长度如果等于3 区县就置空
      if(addressValArr&&addressValArr.length == 3) {
        this.checkXZ = '';
        this.proObj.zCode = ''
      }
      if(addressValArr.length > 0) {
        addressValArr.forEach((item, index) => {
          if (index == 0) {
            this.sheng = item;
            this.proObj.proviceCode = item
          }
          if (index == 1) {
            this.checkCity = item;
            this.proObj.cityCode = item
          }
          if (index == 2) {
            this.checkTownCode = item;
            this.proObj.townCode = item
          }
          if (index == 3) {
            this.checkXZ = item;
            this.proObj.zCode = item
            if((this.trans == '04' || this.trans == '08') && this.proObj.zCode){
              this.getFWF()
            }
          }
        });
      } else {
        this.startTime = ''
        this.startShowTime = ''
      }// 如果之前的三级地址修改了  就清空详细地址
      if(oldAdress !=  this.checkTownCode ){
        this.userDetailAddress = ''
      }
    },
    // 获取用户标签
    getuserTipList() {
      this.pageLoadFlag = true
      let data = {
        custInfoId: this.$store.state.user.userInfo.customer.id
      };
      findListLabel(data).then(res => {
        if(res.data.code == 0) {
          this.userTipsList = res.data.data;
          if( this.userTipsList.length > 0) {
            this.userTipsList.forEach(item => {
              item.checked = false;
            });
          }
          this.pageLoadFlag = false
        }
      });
    },
    // 获取用户来源
    getuserSorceList() {
      this.pageLoadFlag = true
      let data = {
        custInfoId: this.$store.state.user.userInfo.customer.id
      };
      findList(data).then(res => {
        if(res.data.code == 0) {
          this.userSorceList = res.data.data;
        }
        this.pageLoadFlag = false

      });
    },
    getTotalProd()  {
      if(this.orderDetail.orderHeader && this.orderDetail.orderHeader.salesOrderItem){
        return this.orderDetail.orderHeader.salesOrderItem.reduce((sum, item) => {
          return sum + Number(item.bencichuku || 0);
        }, 0)
      } else {
        return 0
      }
    },
    getTotalVol() {
      if(this.orderDetail.orderHeader && this.orderDetail.orderHeader.salesOrderItem){
        return this.orderDetail.orderHeader.salesOrderItem.reduce((sum, item) => {
          return sum + Number(item.bencichuku || 0) * Number(item.volume || 0);
        }, 0)
      } else {
        return 0
      }
    },
    getTotalMoney() {
      if(this.orderDetail.orderHeader && this.orderDetail.orderHeader.salesOrderItem){
        return this.orderDetail.orderHeader.salesOrderItem.reduce((sum, item) => {
          return sum + Number(item.bencichuku) * Number(item.itemCouponModelListBOTTOM && item.itemCouponModelListBOTTOM.length > 0 && item.itemCouponModelListBOTTOM[0].source == "TEACHER" ? item.itemCouponModelListBOTTOM[0].finalPrice : item.bprice);
        }, 0)
      }else {
        return 0
      }
    },
    // getxj(bprice, borderedQty, item) {
    //   item.amount = new Number((bprice || 0) * (borderedQty || 0)).toFixed(2);
    //   return item.amount;
    // },
    // 点击
    changeSorceBtn(item) {
      if(this.SorceIds == item.id) {
        this.SorceIds = ''
        return
      }
      this.SorceIds = item.id
      this.$forceUpdate()

    },

    changeTipsBtn(item) {
      if (!item.checked) {
        if (this.TipsIds.length >= 8) {
          this.$message.warning("用户标签选择不能超过8个");
          return;
        }
      }
      item.checked = !item.checked
      this.$forceUpdate()
      this.TipsIds = []
      if(this.userTipsList.length > 0){
        this.userTipsList.forEach(items=>{
          if(items.checked == true) {
            this.TipsIds.push(items.id)
          }
        })
      }

      this.$forceUpdate()
    },
    // 点击弹窗取消和确定的时候
    chengeOK(item) {
      this.TipsIds = [];
      this.TipsIds = item;
      this.userTipsList.forEach(items=>{
        items.checked = false
        item.forEach(itemB=>{
          if(items.id == itemB) {
            items.checked = true
          }
        })
      })
    },
    // 用户来源点击确定 调接口 增加数据 成功的话赋值
    getReason(value,value2) {
      if (!value) {
        this.SorceIds = value2
        return;
      }
      let data = {
        custInfoId: this.$store.state.user.userInfo.customer.id,
        type: 2,
        source: value,
        remark: ""
      };
      this.pageLoadFlag = true;
      saveInfo(data).then(res => {
        if (res.data.code == 0) {
          this.userSorceList.push(
            {
              id: res.data.data,
              source: value
            }
          );
          this.SorceIds = "";
          this.SorceIds = this.userSorceList[this.userSorceList.length - 1].id;
          this.$forceUpdate();
          this.pageLoadFlag = false;
        } else {
          this.$message.warning(res.data.msg);
          this.pageLoadFlag = false;
        }

      });
    },
    getReasonTip(value, value1) {
      this.pageLoadFlag = true;
      let data = {
        custInfoId: this.$store.state.user.userInfo.customer.id,
        type: 2,
        label: value,
        remark: value1
      };
      saveInfoLabel(data).then(res => {
        if (res.data.code == 0) {
          this.userTipsList.push(
            {
              id: res.data.data,
              label: value,
              checked: true
            }
          );
          this.TipsIds.push(res.data.data)
          this.pageLoadFlag = false;
        } else {
          this.$message.warning(res.data.msg)
          this.pageLoadFlag = false;
        }
      });
    },
    changeSEX(e) {
      this.sexId = e.target.value;
    },
    getAdress(value) {
      let id = null;
      if (this.proObj.townCode) {
        id = this.proObj.townCode;
      } else if (this.proObj.cityCode) {
        id = this.proObj.cityCode;
      } else if (this.proObj.proviceCode) {
        id = this.proObj.proviceCode;
      } else {
        id = 0;
      }

      this.userDetailAddress = value;
      let a = {
        searchstr: this.userDetailAddress,
        region: id
      };
      let b = {
        searchstr: this.userDetailAddress
      };
      let data = id !== 0 ? a : b;
      this.fetching = true;
      getAdressapi(data).then(res => {
        if (res.data.data.pois.length > 0 && res.data.data.pois !== null) {
          this.companyList = [...new Set(res.data.data.pois)]; //缓存列表数据，前端分页
        } else {
          this.companyList = [];
        }

        this.fetching = false;
      });

    },
    //查询数据
    searcha() {

    },
    async sumbitaaa() {
      this.panduanfreeTip();
    },
    // 提示信息 
    getMessage() {
      const msg = this.$configTotal({
        route: this.$route.fullPath,
        id: "14187495767"
      });
      // this.$message.info(msg && msg.msgDesc);
      this.show1Message = msg && msg.msgDesc;

    },
    getMessage1() {
      const msg = this.$configTotal({
        route: this.$route.fullPath,
        id: "14187495772"
      });
      // this.$message.info(msg && msg.msgDesc);
      this.show2Message = msg && msg.msgDesc;
    },
    getMessage2() {
      const msg = this.$configTotal({
        route: this.$route.fullPath,
        id: "14187495874"
      });
      // this.$message.info(msg && msg.msgDesc);
      this.show3Message = msg && msg.msgDesc;
    },
    //  判断商品是否为免标识
    panduanfreeTip() {
      if (this.XSLX == "retail" && (this.fhckType == 20 || this.fhckType == "20")) {
        this.visibleshopLS = true;
      } else if (this.XSLX == "engineering" && (this.fhckType == 20 || this.fhckType == "20")) {
        this.visibleshopGC = true;
      } else {
        this.submitOrder()
      }
    },
    handleRemove(file) {
      const index = file;
      const newFileList = this.fileList.slice();
      newFileList.splice(index, 1);
      this.fileList = newFileList;
    },
    beforeUpload(file) {
      this.pageLoadFlag = true
      if ((file.size) / 1024 > 20480) {
        this.$message.warning("附件大小不能大于20M");
        this.pageLoadFlag = false
        return false;
      } else {
        // 上传附件   调接口
        let account = this.$store.state.user.userInfo ? this.$store.state.user.userInfo.account.account : JSON.parse(localStorage.getItem("userInfo")).account.account;
        let cisCode = this.$store.state.user.userInfo ? this.$store.state.user.userInfo.customer.cisCode : JSON.parse(localStorage.getItem("userInfo")).customer.cisCode;
        let formData = new FormData();
        formData.append("cisCode", cisCode);
        formData.append("businessType", "retailOrder");
        formData.append("file", file);
        const BASE_API = dmsPublicPath;
        let config = {
          headers: { "content-type": "multipart/form-data" }
        };
        axios.post(BASE_API + "/wechatEntrance/entrance.do" + "?account=" + account + "&method=uploadAttachment" + "&type=1", formData, config).then(res => {
          let resResult = JSON.parse(res.data);
          this.pageLoadFlag = false
          if (resResult.code == "0") {
            this.fileList = [...this.fileList, file];
            this.fileList.forEach(item => {
              item.size2 = ((item.size) / 1024).toFixed(2);
            });
            this.ImgIds.push(resResult.data[0].id);
          } else {
            this.$message.warning("上传失败", resResult.msg);
            this.pageLoadFlag = false
          }
        }).catch(error=>{
          this.pageLoadFlag = false
        });

        return false;
      }
      // this.fileList = [...this.fileList, file];
      // return false;
    },
    handleChange({ fileList }) {


      let fileList1 = [...this.fileList];
      this.fileList = fileList1;
      this.fileList.forEach(item => {
        item.size2 = ((item.size) / 1024).toFixed(2);

      });

    },

    getSeverInfo(orgCode, warehouseCode) {
      // if (this.fhckType != 20) {
      //   return
      // }
      this.pageLoadFlag = true;
      this.FWlist = [];
      this.fwlbId = [];
      // 根据销售组织和四级地址获取服务列表数据
      let dataFwl = {
        orgCode: orgCode,
        warehouseCode: warehouseCode,
        provinceCode: this.proObj.proviceCode,
        cityCode: this.proObj.cityCode,
        countyCode: this.proObj.townCode,
        townCode: this.proObj.zCode

      };
      getSeverList(dataFwl).then(res => {

        this.pageLoadFlag = false;
        // 获取服务列表数据
        let data;
        if (typeof res.data == "string") {
          data = JSON.parse(res.data);
        } else {
          data = res.data;
        }
        // 目前接口还不通,需要修改赋值参数
        this.FWlist = data.data;

        // 默认选中
        if (this.FWlist && this.FWlist.length > 0) {
          for (const item of this.FWlist) {
            if (item.isDefault == 1 && item.isSupport == 1) {
              this.fwlbId.push(item.serviceCode);
            }
          }
        }

      }).catch(res => {

        this.pageLoadFlag = false;
      });
    },
    // handleChangeAddressFW(value, e) {
    //   // 获取选中的服务列表的id
    //   this.serverBox = (e.map(item=>item.key)).toString()
    // },
    // 送装一体修改选项
    changeSZtype(e) {
      this.szytType = this.SZOptions[e.target.value].id;
    },
    // isEnableOrNotBuchaUpdate() {
    //   if (!this.receiveID) {
    //     return;
    //   }
    //   let data = {
    //     cisCode: this.$store.state.user.userInfo.customer.cisCode,
    //     orgCode: this.receiveID
    //   };
    //   isEnableOrNotBucha(data).then(res => {
    //     if(!res.data){
    //       return
    //     }
    //     const resData = JSON.parse(res.data);
    //     this.isEnableOrNot = resData.data;
    //   });
    // },

    getMetarial() {
      if (!this.receiveXSID ) {
        this.materialGroup = [];
        return;
      }
      findMaterialByStore({
        storeCode: this.receiveXSID
        // orgId: this.receiveID
      }).then(res => {
        let data;
        if (typeof res.data == "string") {
          data = JSON.parse(res.data);
        } else {
          data = res.data;
        }
        this.materialGroup = data.materialGroup;
      });
    },
    getCustromType() {
      // 17451是渠道采购，17452 自营或者17453 海信采购 （可能同时存在）
      // 自营
      if (this.isFenxiaoOrder.indexOf("17452") !== -1 && this.isFenxiaoOrder.indexOf("17451") == -1 && this.isFenxiaoOrder.indexOf("17453") == -1) {
        return 5;
      }
      if (
        // 代理
        (this.isFenxiaoOrder.indexOf("17452") !== -1 ||
          this.isFenxiaoOrder.indexOf("17453") !== -1) &&
        this.isFenxiaoOrder.indexOf("17451") == -1
      ) {
        return 1;
      } else if (
        //分销
        this.isFenxiaoOrder.indexOf("17451") !== -1 &&
        this.isFenxiaoOrder.indexOf("17452") == -1 &&
        this.isFenxiaoOrder.indexOf("17453") == -1
      ) {
        return 2;
      } else if (
        // 分销直营 不显示
        //共存 包括分销或者不包括直营 并且是海信 return 3 显示按钮
        (this.isFenxiaoOrder.indexOf("17451") !== -1 || this.isFenxiaoOrder.indexOf("17452") == -1) && this.isFenxiaoOrder.indexOf("17453") !== -1
      ) {
        return 3;
      } else if (this.isFenxiaoOrder.indexOf("17451") !== -1 && this.isFenxiaoOrder.indexOf("17452") !== -1 && this.isFenxiaoOrder.indexOf("17453") == -1) {
        return 4;
      }


    },
    //搜索按钮事件
    search() {
      if (!this.searchIpt || this.searchIpt.length < 2) {
        const msg = this.$configTotal({
          route: this.$route.fullPath,
          id: "14180000066"
        });
        this.$message.info(msg && msg.msgDesc);
        // this.$message.info("请输入至少两个字符进行搜索！");
        return;
      }
      this.pager.pageNo = 1;
      this.getData();
    },
    pageSearch(pager) {
      this.pager.pageNo = pager.pageNo;
      this.pager.pageSize = pager.pageSize;
      this.getData();
    },
    //调接口获取数据
    getData() {
      this.tabelIsLoading = true;
      const tabeData = {
        cisCode: this.$store.state.user.userInfo.customer.cisCode,
        terms: {
          model: this.searchIpt,
          colour: "",
          warehouseId: this.receiveFHID,
          invStatusId: "",
          isLock: "",
          dealerMaterialGroupFlag: "",
          materialGroupCode: "",
          materialGroupName: "",
          gicWarehouseType: "",
          invStatusType: "",
          invType: "",
          bigQtyMin: 0,
          orgId: this.receiveID
        },
        page: {
          pageNo: this.pager.pageNo,
          pageSize: this.pager.pageSize
        }
      };
      getProductList(tabeData).then(res => {
        this.tabelIsLoading = false;
        let data;
        if (typeof res.data == "string") {
          data = JSON.parse(res.data);
        } else {
          data = res.data;
        }
        const start = (this.pager.pageNo - 1) * this.pager.pageSize;
        data.data.forEach(it => {
          it.borderedQty = 1;
          it.invStatus = it.invStatusId;
          it.bigUom = it.uom;
          it.uid = this.genID(10);
        });
        this.selRows = []; //切换页码清空勾选的行
        this.onlyShowChecked = false;
        this.pager.count = data.page.totalCount;
        this.dataCache = data.data; //缓存列表数据，前端分页
        this.dataList = data.data.slice(start, start + this.pager.pageSize);

      });
      // }
    },
    //生成不重复id/后端返回的列表没有唯一键
    genID(length) {
      return Number(
        Math.random()
          .toString()
          .substr(3, length) + Date.now()
      ).toString(36);
    },
    editRow(item) {
      this.rowSelectionType = "radio";
      this.searchIpt = item.model;
      this.selRow = item;
      this.isVisible = true;
      this.getData();
    },

    getywy() {
      let data = {
        cisCode: this.$store.state.user.userInfo.customer.cisCode,
        userAccount: this.$store.state.user.userInfo.account.account,
        type: "ywy"
      };
      findBaseData(data).then(res => {
        var obj = JSON.parse(res.data);
        this.ywy = Object.keys(obj.data[0])[0];
      });
    },
    // 开票方
    getkpf() {
      let data = {
        cisCode: this.$store.state.user.userInfo.customer.cisCode,
        userAccount: this.$store.state.user.userInfo.account.account,
        type: "kpf"
      };
      findBaseData(data).then(res => {
        var obj = JSON.parse(res.data);
        this.kpf = Object.keys(obj.data[0])[0];
      });
    },
    // 销售组织list
    getOrg() {
      let data = {
        customerCisCode: this.$store.state.user.userInfo.customer.cisCode
      };
      getOrgList(data).then(res => {
        var realDate = JSON.parse(res.data);

        // 销售组织
        var XSZZ = [];
        for (let item of realDate.data) {
          for (let key in item) {
            XSZZ.push({
              key,
              value: item[key]
            });
          }
        }
        if (XSZZ.length > 0) {

          this.receive = XSZZ[0].value;
          this.receiveID = XSZZ[0].key;
          this.orderDis = XSZZ;
          this.xszhId = XSZZ[0].value;

        }
      });

    },
    // 零售订单基础信息
    basicMessage() {
      let data = {
        account: this.$store.state.user.userInfo.customer.cisCode,
        cisCode: this.$store.state.user.userInfo.customer.cisCode,
        userAccount: this.$store.state.user.userInfo.account.account,
        orgId: ""
      };
      this.pageLoadFlag = true;
      getBasicMessage(data).then(res => {
        var realDate = JSON.parse(res.data);
        this.realObj = realDate;
        // 零售客户
        // 销售门店
        var XSMDS = [];
        for (let key in realDate.baseInfo.storeInfo) {
          XSMDS.push({
            key,
            value: realDate.baseInfo.storeInfo[key].name,
            isSpeclalShop:realDate.baseInfo.storeInfo[key].isSpeclalShop,
            address:realDate.baseInfo.storeInfo[key].address,
            provinceCode:realDate.baseInfo.storeInfo[key].provinceCode,
            cityCode:realDate.baseInfo.storeInfo[key].cityCode,
            countyCode:realDate.baseInfo.storeInfo[key].countyCode,
            townCode:realDate.baseInfo.storeInfo[key].townCode,
          });
        }
        this.XSMD = XSMDS;
        //   获取详情数据
        this.getDetails()
        // 收货地址
        // 省
        var adres = realDate.baseInfo.address;
        let pro = Object.keys(adres.province)[0];
        let citykey = Object.keys(adres.city)[0];
        let countrykey = Object.keys(adres.country)[0];
        let townkey = Object.keys(adres.town)[0];
        this.proObj.proviceCode = pro;
        this.proObj.cityCode = citykey;
        this.proObj.townCode = countrykey;
        this.proObj.zCode = townkey;
        this.receivehisSH =
          adres.province[pro] +
          adres.city[citykey] +
          adres.country[countrykey] +
          adres.town[townkey];
        this.sheng = adres.province[pro];
        this.checkCity = adres.city[citykey];
        this.checkTownCode = adres.country[countrykey];
        this.checkXZ = adres.town[townkey];
        this.pageLoadFlag = false;
      }).catch(res => {
        this.pageLoadFlag = false;
      });
    },

    getWarehouseList() {
      // 根据销售组织获取仓库类型
      //发货地址改动接口 -》 根据仓库类型（20共享仓，70原仓）判断是否选择服务列表-》20统仓 选
      this.pageLoadFlag = true;
      getWarehouseList({ orgId: this.receiveID }).then(res => {
        this.pageLoadFlag = false;
        let data_ = JSON.parse(res.data);
        // 发货仓库
        let FHCK = [];
        for (let item of data_.data) {
          FHCK.push({
            key: item.cId,
            value: item.name,
            type: item.type
          });
        }
        if (FHCK.length > 0) {
          this.receiveFHS = FHCK[0].value;
          this.fhckId = FHCK[0].value;
          this.receiveFHID = FHCK[0].key;
          this.receiveFH = FHCK;
          //仓库类型 -》 20统舱，70原仓
          this.fhckType = FHCK[0].type;
          //根据仓库的默认第一项判断获取服务列表 及是否展示
          if (this.fhckType == 20) {
            this.getSeverInfo();
          }
        } else {
          this.receiveFHS = "";
          this.fhckId = "";
          this.receiveFHID = "";
          this.receiveFH = [];
          this.fhckType = "";
          this.fwlbId = [];
        }

      }).catch(res => {
        this.pageLoadFlag = false;
      });
    },
    onChange(selKeys, selRows) {},
    // 销售门店
    receiveAddressXS() {
      this.receiveAddXS = !this.receiveAddXS;
    },
    // 发货仓库
    receiveAddressFH() {
      this.receiveAddFH = !this.receiveAddFH;
    },
    handleChangeAddressXS(value, e) {
      let obj = this.XSMD.find((i) => {
        return i.key === e.key;//筛选出匹配数据
      });
      this.mdlx = obj.isSpeclalShop
      this.shengCode = obj.provinceCode
      this.checkCity = obj.cityCode
      this.checkTownCode = obj.countyCode
      this.checkXZ = obj.townCode
      this.proObj.proviceCode = obj.provinceCode
      this.proObj.cityCode = obj.cityCode
      this.proObj.townCode = obj.countyCode
      this.proObj.zCode = obj.townCode
      // this.userDetailAddress = obj.address
      // this.addressHx = [this.shengCode,this.checkCity,this.checkTownCode,this.checkXZ]
      this.receiveXS = value;
      // 收货地址 选中 id
      this.receiveXSID = e.key;
      this.getMetarial();
      this.receiveAddXS = !this.receiveAddXS;
    },

    showModal() {
      this.visible = true;
    },
    handleOk(e) {
      this.visible = false;
    },
    // 查询服务费
    getFWF(){
      if(this.orderDetail.orderHeader && this.orderDetail.orderHeader.salesOrderItem && this.orderDetail.orderHeader.salesOrderItem.length>0) {
        if( !this.proObj.zCode ){
          this.$message.warning('请选择省市区县')
          return
        }
        this.startTime = ''
        this.startShowTime = ''
        let orderServiceFeeItemDtoList = []
        this.orderDetail.orderHeader.salesOrderItem.forEach(item=>{
          let wareHouseList = item.tableList.filter(it=>it.numberThis > 0)
          orderServiceFeeItemDtoList.push({
            "productId": item.productCode,		//产品id
            "warehouseInfoId": wareHouseList.length>0?wareHouseList[0].gicWarehouse:'',			//仓库werks-lgort，例如2600-1532，dms代理商下单必传，其他情况传空
            "qty": item.bencichuku,
            zzprdmodel:item.model,
            orgId:this.orgCode,
          })
        })
        let data = {
          "townCode":  this.proObj.zCode,
          "countyCode": this.proObj.townCode,
          "cityCode":  this.proObj.cityCode,
          "provinceCode":  this.proObj.proviceCode ,
          "warehouseInfoId": '',			//发货仓库id，信天翁代理商下单必填，信天翁分销商下单或者dms下单必须传空
          "shopCode":  '',			//门店idthis.receiveXSID
          "documentSource":"5",			//dms下单传5，其他随便传个不是5的数就行
          "orderServiceFeeItemDtoList": orderServiceFeeItemDtoList,
          trans:this.trans
        }
        this.pageLoadFlag = true
        findMoneyBuyBegin(data).then(res => {
          this.pageLoadFlag = false
          if(res.data && res.data.data && res.data.data.timelinessDate){
            this.startTime =  res.data.data.timelinessDate
              // ?res.data.data.timelinessDate.replace(/-/g,"/"):''
            this.startShowTime = res.data.data.timelinessDate
              // ?res.data.data.timelinessDate.replace(/-/g,"/"):''
          } else{
            //  如果接口没返回数据 就默认当前日期加3天
            this.startTime =  this.$util.getDateSpecify(3)
            this.startTime = this.startTime.replace(/\//g,"-")
            this.startShowTime = this.$util.getDateSpecify(3)
            this.startShowTime =   this.startShowTime.replace(/\//g,"-")
          }
          if(res.data.code == 0){
            this.serviceCharge = res.data.data.totalAmount
            this.orderServiceFeeItemDtoList = res.data.data.orderServiceFeeItemDtoList
            this.orderServiceFeeItemDtoList.forEach((itemFirst,indexFirst)=>{
              this.orderDetail.orderHeader.salesOrderItem.forEach((item,index)=>{
                if( itemFirst.productId == item.productCode ){
                  this.$nextTick(()=> {
                    this.$set(item,'serviceMoney',itemFirst.serviceFee)
                  })
                }
              })
            })
          }
          if(res.data.code == 1 ){
            this.serviceCharge = 0
            this.orderDetail.orderHeader.salesOrderItem.forEach((item,index)=>{
              this.$nextTick(()=> {
                this.$set(item,'serviceMoney','0.00')
              })
            })
          }
        }).catch(error=>{
          //  如果接口没返回数据 就默认当前日期加3天
          this.startTime =  this.$util.getDateSpecify(3)
          this.startTime = this.startTime.replace(/\//g,"-")
          this.startShowTime = this.$util.getDateSpecify(3)
          this.startShowTime =   this.startShowTime.replace(/\//g,"-")
        })
      }

    },
    // 获取配送方式
    getFxGetDeliveryMethod(){
      // 如果配送方式和勾选数据不符合 就提示
      let optionKey = this.deliveryOptions.map(it=>{
        return it.code
      })
      if( !optionKey.includes(this.trans) ){
        this.trans = ''
      }
      const items = [], orgMatkl = []
      let productList =  this.orderDetail.orderHeader.salesOrderItem
      if(productList && productList.length > 0) {
        productList.forEach(el => {
          let tableList = el.tableList.filter(it=>it.numberThis>0)
          if(tableList.length>0){
            if(orgMatkl.length == 0 || !orgMatkl.includes(`${el.orgCode}-${el.materialGroupCode}`)) {
              orgMatkl.push(`${el.orgCode}-${el.materialGroupCode}`)
            }
          }
        })
        if(orgMatkl.length>0){
          orgMatkl.forEach((it, index) => {
            items.push({
              orgId: it.split('-')[0],
              matklCode: it.split('-')[1],
              productIds: []
            })
            productList.forEach(el => {
              let aa = el.tableList.filter(tableIt=>tableIt.numberThis>0)
              el.gicWarehouse = aa.length>0?aa[0].gicWarehouse:''
              el.gicWarehouseType= aa.length>0?aa[0].gicWarehouseType:''
              if(it == `${el.orgCode}-${el.materialGroupCode}`) {
                items[index].productIds.push({
                  id: el.productCode,
                  warehouseName: el.gicWarehouse,
                  isShareWarehouse: el.gicWarehouseType == '20' ? true : false // 是否共享仓
                })
              }
            })
          })

        }
      }
      let data = {
        orderType: this.XSLX == 'retail' ? 3 : 4, // 订单类型-3：零售录入（零售），4：零售录入（工程）
        // serviceCode: 'cisFxZsyhGetDeliveryMethod',
        code:this.$store.state.user.userInfo.customer.cisCode,
        fxCustCisCode: this.$store.state.user.userInfo.customer.cisCode,
        dlCustCisCode:this.$store.state.user.userInfo.customer.cisCode,
        items: items,
      }
      this.pageLoadFlag = true
      fxZsyhGetDeliveryMethod(data).then(res=>{
        this.pageLoadFlag = false
        if(res.data.code == 0) {
          let oldDeliveryOptions  =  res.data.data.items
          this.deliveryOptions = res.data.data.items
          this.isShowLogisticsIcon = res.data.data.items.some(el => ['04', '08'].includes(el.code))
          // 获取接口中check == true 默认选中的下标
          this.transDefault = this.deliveryOptions.findIndex(el => el.check == true)>-1?this.deliveryOptions.findIndex(el => el.check == true):''

          // 是否展示服务费 根据配送方式中字段判断 // 0否，1是
          this.haveDeliveryFee = this.deliveryOptions[this.transDefault].haveDeliveryFee == 1? true : false
          if((this.trans == '04' || this.trans == '08') && this.proObj.zCode){
            this.getFWF()
          }
          // 赋值配送方式
          this.trans = this.deliveryOptions[this.transDefault].code


          if(this.getTotalProd() <4 ) {
            let newArr = oldDeliveryOptions.filter(it=>it.code!=='07')
            this.deliveryOptions =  newArr
            if(this.trans == '07'){
              this.transDefault = this.deliveryOptions.findIndex(el => el.check == true)>-1?this.deliveryOptions.findIndex(el => el.check == true):''
              // 赋值配送方式
              this.trans = this.deliveryOptions[this.transDefault].code
              this.haveDeliveryFee = this.deliveryOptions[this.transDefault].haveDeliveryFee == 1? true : false
              this.isShowLogisticsIcon = oldDeliveryOptions.some(el => ['04', '08'].includes(el.code))
            }
            this.$forceUpdate()
          }else {
            this.deliveryOptions = oldDeliveryOptions
          }
        }
      })
    },
    onGiveType(e) {
      // this.trans = this.deliveryOptions[e.target.value].code;
      this.trans = e.target.value
      let obj = this.deliveryOptions.find((i) => {
        return i.code === this.trans;//筛选出匹配数据
      });
      this.haveDeliveryFee = obj&&obj.haveDeliveryFee == 1? true : false
      this.startTime =  ''
      this.startShowTime = ''
      if((this.trans == '04' || this.trans == '08') && this.proObj.zCode){
        this.getFWF()
      }
      // 如果仓库类型为同仓并且 配送方式为工程  不显示服务列表 并且 传值为空
      if (this.fhckType == "20" && this.trans == "07") {

        this.FWlist = [];
        this.fwlbId = [];
        return;

      } else if (this.fhckType == "20" && this.trans !== "07") {
        this.getSeverInfo();
        return;
      }
    },
    // 分销商 分销地址切换获取手机号
    showAnotheer() {
      getPhone({
        sendToId: this.distributionAdd.DISCODE
      }).then(() => {
      });
    },
    onSubmit() {
      //form
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
        } else {
          return false;
        }
      });
    },
    resetForm() {
      this.$refs.ruleForm.resetFields();
    },
    // 点击选择开票户头
    choseAccount() {
      // axios请求后台开票户头
    },

    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    // 用户级联选择改变
    cityChange(value) {
    },
    // 判断当前账户是否签约贷款合同
    getisKQ(){
      let data = {
        serviceCode:'queryDeliveryAmountFind',
        mdmCode:this.$store.state.user.userInfo.customer.customerCode
      }
      getDeliveryAmountFind(data).then(res=>{
        if(res.data.success == 'true'){
          this.showTIP = res.data.msg=='true'?true:false
          this.$forceUpdate()
        }else {
          this.showTIP = false
        }
      })
    },
    gotoCK(){
      this.$router.push({
        path:"/stockCenter/myStock"
      });
    },
    dataChange(e){
      if((this.trans == '04' || this.trans == '08') && this.proObj.zCode){
        this.getFWF()
      }
    },
    // 销售类型
    // 销售类型
    onGiveTypeLX(e) {
      this.trans = ''
      this.deliveryOptions=[]
      // this.XSLX = this.saleOptions[e.target.value].id;
      this.XSLX = e.target.value
      this.getFxGetDeliveryMethod();
      if((this.trans == '04' || this.trans == '08') && this.proObj.zCode){
        this.getFWF()
      }
      const saleInfo = {
        xsmdId: this.xsmdId,
        receiveXSID:this.receiveXSID,
        sheng:this.sheng,
        checkCity:this.checkCity,
        checkTownCode:this.checkTownCode,
        checkXZ:this.checkXZ,
        proviceCode:this.proObj.proviceCode,
        cityCode:this.proObj.cityCode,
        townCode:this.proObj.townCode,
        zCode:this.proObj.zCode,
        XSLX:this.XSLX
      };
      localStorage.setItem(this.$store.state.user.userInfo.account.account, JSON.stringify(saleInfo));

    },
    // 是否依旧换新
    oldNewChange(e) {
      this.isOldNew = e.target.value;
    },
    // 完成收旧
    completeOldChange(e) {
      this.isCompleteOld = e.target.value;
    },
    // 直送用户/送装一体地区校验
    async deliveryAddressCheck() {
      const orgparamsList = this.orderDetail.orderHeader.salesOrderItem.map(el => {
        return {
          orgId: el.orgCode,
          matklId: el.materialGroupCode
        }
      })
      let data = {
        "serviceCode":"cisDeliveryAddressCheck",
        "provinceCode":this.proObj.proviceCode,
        "cityCode":this.proObj.cityCode,
        "districtCode":this.proObj.townCode,
        "townCode":this.proObj.zCode,
        "custId":this.$store.state.user.userInfo.customer.id,
        orgparamsList:orgparamsList,
        orderType:1,
        deliveryMethod:this.trans,
        haveDeliveryFee:this.haveDeliveryFee?1:0,// 是否展示服务费
      }
      return ctsApi(data)
    },
    // 是否是璀璨试点区域
    isBrightMethods(){
      return isBrightPilotRegion({regionTownCode:this.proObj.zCode})
    },
    // dms 订单提交
    async submitOrder() {
      const params = []
      this.orderDetail.orderHeader.salesOrderItem.forEach(el => {
        el.tableList.forEach(child => {
          if(child.numberThis > 0){
            params.push({
              productCode: child.productCode,
              materialCode: child.materialCode, // 物料编码
              model: child.model,
              invStatus: child.invStatusId, // 库存状态id
              invStatusType: child.invStatusType, // 补差类型
              borderedQty: el.bencichuku, // 销售数量
              bprice: Number(el.itemCouponModelList && el.itemCouponModelList.length > 0 && el.itemCouponModelList[0].source == "TEACHER" ? el.itemCouponModelList[0].finalPrice : el.bprice).toFixed(2), // 销售价格
              amount: Number(el.itemCouponModelList && el.itemCouponModelList.length > 0 && el.itemCouponModelList[0].source == "TEACHER" ? el.itemCouponModelList[0].finalPrice : el.bprice)*Number(el.bencichuku), // 小计
              gicWarehouse: child.gicWarehouse, // 仓库id
              orgCode: child.orgCode, // 组织id
            })
          }
        })
      })
      if(params.length <= 0 ){
        this.$message.warning('请选择出库仓库');
        return false
      } else {
        for(let i = 0; i < this.orderDetail.orderHeader.salesOrderItem.length; i++){
          if(this.orderDetail.orderHeader.salesOrderItem[i].borderedQty !=this.orderDetail.orderHeader.salesOrderItem[i].bencichuku){
            this.$message.warning('明细中出库数量需和下单数量一致');
            return false
          }
        }
      }
      if (!this.userDetailAddress) {
        // this.$message.warning("请输入详细地址");
        const msg = this.$configTotal({
          route: this.$route.fullPath,
          id: "14180000081"
        });
        this.$message.warning(msg && msg.msgDesc);
        this.isLoading = false
        return;
      }
      if (!this.trans) {
        // this.$message.warning("请选择配送方式");
        const msg = this.$configTotal({
          route: this.$route.fullPath,
          id: "14180000071"
        });
        this.$message.warning(msg && msg.msgDesc);
        this.isLoading = false
        return;
      }
      if (!(
        this.proObj.zCode &&
        this.proObj.proviceCode &&
        this.proObj.cityCode &&
        this.proObj.townCode
      )
      ) {
        // this.$message.warning("请选择直配地址");
        const msg = this.$configTotal({
          route: this.$route.fullPath,
          id: "14180000080"
        });
        this.$message.warning(msg && msg.msgDesc);
        this.isLoading = false
        return;
      }
      if (!this.userDetailAddress) {
        // this.$message.warning("请输入详细地址");
        const msg = this.$configTotal({
          route: this.$route.fullPath,
          id: "14180000081"
        });
        this.$message.warning(msg && msg.msgDesc);
        this.isLoading = false
        return;
      }
      if (!this.XSLX) {
        // this.$message.warning("请选择销售类型");
        const msg = this.$configTotal({
          route: this.$route.fullPath,
          id: "14180000073"
        });
        this.$message.warning(msg && msg.msgDesc);
        this.isLoading = false
        return;
      }
      if (!this.business.code) {
        // this.$message.warning("请输入零售客户手机号");
        const msg = this.$configTotal({
          route: this.$route.fullPath,
          id: "14180000077"
        });
        this.$message.warning(msg && msg.msgDesc);
        this.isLoading = false
        return;
      }
      if (this.business.code.trim().length !== 11) {
        this.$message.warning("手机号不正确，请重新填写");
        this.isLoading = false
        return;
      }
      if (!this.business.name) {
        // this.$message.warning("请输入零售客户姓名");
        const msg = this.$configTotal({
          route: this.$route.fullPath,
          id: "14180000078"
        });
        this.$message.warning(msg && msg.msgDesc);
        this.isLoading = false
        return;
      }
      if (!this.receiveXSID) {
        // this.$message.warning("请选择销售门店");
        const msg = this.$configTotal({
          route: this.$route.fullPath,
          id: "14180000079"
        });
        this.$message.warning(msg && msg.msgDesc);
        this.isLoading = false
        return;
      }
      if (!this.business.name) {
        // this.$message.warning("请输入零售客户姓名");
        const msg = this.$configTotal({
          route: this.$route.fullPath,
          id: "14180000078"
        });
        this.$message.warning(msg && msg.msgDesc);
        return;
      }


      const index1 = this.deliveryOptions.findIndex(el => el.code == '04')
      if(['04'].includes(this.trans) && this.deliveryOptions[index1].msg) {
        this.$message.warning(this.deliveryOptions[index1].msg)
        return false
      }
      // 判断下单产品是否支持送装一体
      const index2 = this.deliveryOptions.findIndex(el => el.code == '08')
      if(['08'].includes(this.trans) && this.deliveryOptions[index2].msg) {
        this.$message.warning(this.deliveryOptions[index2].msg)
        return false
      }
      const index3 = this.deliveryOptions.findIndex(el => el.code == '09')
      if(['09'].includes(this.trans) && this.deliveryOptions[index3].msg) {
        this.$message.warning(this.deliveryOptions[index3].msg)
        return false
      }
      //
      const index4 = this.deliveryOptions.findIndex(el => el.code == '10')
      if(['10'].includes(this.trans) && this.deliveryOptions[index4].msg) {
        this.$message.warning(this.deliveryOptions[index4].msg)
        return false
      }
      if(['04', '08'].includes(this.trans) ) {
        // const index1 = this.deliveryOptions.findIndex(el => el.code == '04')
        // if(['04'].includes(this.trans) && this.deliveryOptions[index1].msg) {
        //   this.$message.warning(this.deliveryOptions[index1].msg)
        //   return false
        // }
        // // 判断下单产品是否支持送装一体
        // const index2 = this.deliveryOptions.findIndex(el => el.code == '08')
        // if(['08'].includes(this.trans) && this.deliveryOptions[index2].msg) {
        //   this.$message.warning(this.deliveryOptions[index2].msg)
        //   return false
        // }
        // const index3 = this.deliveryOptions.findIndex(el => el.code == '09')
        // if(['09'].includes(this.trans) && this.deliveryOptions[index3].msg) {
        //   this.$message.warning(this.deliveryOptions[index3].msg)
        //   return false
        // }
        // //
        // const index4 = this.deliveryOptions.findIndex(el => el.code == '10')
        // if(['10'].includes(this.trans) && this.deliveryOptions[index4].msg) {
        //   this.$message.warning(this.deliveryOptions[index4].msg)
        //   return false
        // }

        const res =  await this.deliveryAddressCheck()
        if(res.data.code == 1) {
          this.$message.warning(res.data.msg)
          return false
        }
        if(this.userDetailAddress == '' ){
          this.$message.warning('请填写详细地址');
          this.isLoading = false
          return;
        }
        // 获取产品是璀璨的长度
        let brightRow = this.orderDetail.orderHeader.salesOrderItem.filter(it=>it.productLabel&&it.productLabel.indexOf('15691143849')>-1)
        // 产品有璀璨 并且 有省市区县 并且是直送用户或者送装一体
        if (this.proObj.townCode&&brightRow.length>0) {
          const response = await this.isBrightMethods()
          this.isBright = response.data.code == 0 ?response.data.data:false
        }
      }else {
        this.isBright = false
      }
      // 工程机禁止录零售
      const isExitList = this.orderDetail.orderHeader.salesOrderItem.filter(el => el.productLabel.indexOf('14182273110') > -1)
      if(isExitList.length > 0 && this.XSLX == 'retail') {
        const modelList = isExitList.map(el => el.model)
        this.$message.warning(`${modelList.join('、')}为工程机，不能录入零售订单，请选择销售类型工程`)
        return false
      }
      if(this.openCloseBtn){
        if (!this.cherkStatus) {
          this.$message.warning("请确认质物明细！");
          return;
        }
      }



      // 组装数据
      let data = {
        cisCode: this.$store.state.user.userInfo.customer.cisCode,
        userAccount: this.$store.state.user.userInfo.account.account,
        salesOrder: {
          documentNum: this.documentNum, // 零售单号
          edt: this.startTime?this.startTime+ ' 23:59:59' : '',// 期望时间
          phone: this.business.code, // 电话
          deliveryMode: this.trans, // 配送方式
          fullAddress: this.userDetailAddress, // 地址详情
          storeCode:  this.receiveXSID, // 门店
          isGroup: 0, // 此参数无用，传上后端不报错，后端：刘传伟
          attachmentIds: this.ImgIds.join(","), // 附件id，以逗号隔开
          isOldNew: this.isOldNew, // 是否依旧换新
          isCompleteOld: this.isCompleteOld,
          oldMachCategory: this.oldMachCategory,
          oldMachCategoryName: this.oldMachCategoryName,
          oldMachTreatWay: this.oldMachTreatWay,
          oldMachTreatWayName: this.oldMachTreatWayName,
          retailType: this.XSLX, // 销售类型
          provinceId: this.proObj.proviceCode,
          cityId: this.proObj.cityCode,
          townId: this.proObj.zCode,
          countryId: this.proObj.townCode,
          message: this.BZvalue,
          customerName:this.business.name, // 客户名
          sellerCode: this.ywy, // 业务员
          gender: this.sexId?this.sexId:'', // 性别d, // 性别
          customerLabels:this.TipsIds?this.TipsIds.join(','):'', // 用户标签
          sourceId: this.SorceIds?this.SorceIds:'', // 来源
          haveDeliveryFee: this.haveDeliveryFee?1:0,
          salesOrderItem: params,
          brightFlag: this.isBright
        }
      }
      this.isLoading = true;
      await createXxmRetailOrder(data)
        .then(res => {
          var obj = JSON.parse(res.data);
          if (obj.code == "1") {
            this.visibleAAA = true
            this.showInfo = obj.msg
          } else if (obj.code == "0") {
            if(obj.deliveryOrderNum) {
              this.$router.push({
                path: "/serviceFeeModule/pay",
                query: {
                  id:obj.deliveryOrderNum,
                  fwfOrder:obj.deliveryOrderNum,
                  batch:0,
                  b2bOrderCode: obj.msg,
                  type: "retail",
                  orgName: "订单号",
                  data:obj.data,
                  channel: 2
                }
              });
              return
            }
            this.$router.push({
              path: "/confirm/inputSuccess",
              query: {
                b2bOrderCode: obj.msg,
                type: "retail",
                orgName: "订单号",
                data:obj.data
              }
            });
          }
          this.isLoading = false;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    // 订单类型切换
    orderChange(type) {
      this.$emit("changeType", type);
    },
    disabledDateS(current) {
      // let nowDate = moment().format("YYYY-MM-DD")
      // 目前这个值是写死的 后期需要对接口
      let three = moment(this.startShowTime)
      // return current < moment().startOf('days') || current > new Date(this.$util.getDate30())
      return current < moment(this.startShowTime) || current > three.add(29, 'd')
    },
    arrivalTimeFocus(){
      if(!this.startTime && this.haveDeliveryFee){
        this.$message.warning('请先填选相关信息')
        return
      }else {
        this.endOpen = true;
      }
    },
    handleEndOpenChange(){
      if(this.startTime){
        this.endOpen = true;
        return
      }
    },
    // 时间更改
    handleTimeChange(e, defaultTime) {
      this.startTime = defaultTime;
      this.endOpen = false;
    },
    // 控制可选时间范围
    disabledDate(current) {
      return current && current < moment().endOf("day");
    },
    // 获取子组件选中商品
    sonCheckList(data) {
      let cheacked = this.sonChectItme.indexOf(data);
      if (this.sonChectItme.indexOf(data) == -1) {
        this.sonChectItme.push(data);
      } else {
        this.sonChectItme.splice(cheacked, 1);
      }

    },
    //   点击返回购物车
    goCar() {
      this.$router.push("/channelOrder/orderList?type=retail");
    },
    // 收货地址点击选择
    receiveAddress() {
      this.receiveAdd = !this.receiveAdd;
    },
    // 销售组织
    handleChangeAddress(value, e) {
      this.receive = value;
      // 收货地址 选中 id
      this.receiveID = e.key;
      // this.SAddressChange(e.key)
      this.receiveAdd = !this.receiveAdd;
      this.basicMessage(e.key);
      this.getWarehouseList();

    },
    // 收货地址
    hishandleChangeAddress(value, e) {
      this.test = value;
      this.distributionAdd.DISCODE = e.key;
      this.officeCode = e.key;
      this.hisenseOff = !this.hisenseOff;
      this.showAnotheer();
    },
    // 切换收货地址
    SAddressChange(id) {
      showAnother({
        id
      })
        .then(res => {
        })
        .catch(err => {
          console.log(err);
        });
    },
    // 分销商显示隐藏
    choseDistr() {
      this.distr = !this.distr;
    },
    // 切换分销商
    handleChangeDistr(value, e) {
      this.distrName = value;

      this.DISADDPARAMS.customerId = e.key;
      this.getDISADDList();
    },
    // 分销商切换获取分销商地址list
    getDISADDList() {
      let data = this.DISADDPARAMS;
      getDISAdd(data)
        .then(res => {

          this.choseDistribution = res.data;
          this.distributionAdd.DISCODE = res.data[0].id;
        })
        .catch(err => {
          console.log(err);
        });
    },
    distrAddShow() {
      this.distrAdd = !this.distrAdd;
    },
    // 选择经销商地址
    handleChangeDistrAdd(value, e) {
      this.distributionAdd.DISCODE = e.data.key;
      this.distributionAdd.DISADD = value;

      this.distrAddress = value;
      getPhone({
        sendToId: e.key
      }).then(res => {
      });
    },
    // 分销商地址切换获取联系人
    getPeople() {
      getPhone();
    },
    // 弹框
    openAdd(e,e1) {
      this.rowSelectionType = "checkbox";
      if(e !== null) {
        this.searchStr = e
        this.proIndex = e1
      }
      this.isVisible = true;
      if (!this.searchIpt || this.searchIpt.length < 2) {
        this.selRows = []; //切换页码清空勾选的行
        this.onlyShowChecked = false;
        this.pager.count = 0;
        this.dataCache = []; //缓存列表数据，前端分页
        this.dataList = [];
        return;
      }
      this.getData();
    },
    // 海信办事处
    hisenseOffice() {
      this.hisenseOff = !this.hisenseOff;
    },
    // 获取旧机品类
    getOldMachCategory() {
      let data = {
        cisCode: this.$store.state.user.userInfo.customer.cisCode,
        userAccount: this.$store.state.user.userInfo.account.account,
        type: "xsddjjpl"
      };
      findBaseData(data).then(res => {
        res = JSON.parse(res.data).data;
        for (let item of res) {
          let obj = {};
          let key = Object.keys(item)[0];
          obj.key = key;
          obj.value = item[key];
          this.oldMachCategorys.push(obj);
        }

      });
    },
    // 获取旧机处理途径
    getOldMachTreatWays() {
      let data = {
        cisCode: this.$store.state.user.userInfo.customer.cisCode,
        userAccount: this.$store.state.user.userInfo.account.account,
        type: "xsddjjcltj"
      };
      findBaseData(data).then(res => {
        res = JSON.parse(res.data).data;
        for (let item of res) {
          let obj = {};
          let key = Object.keys(item)[0];
          obj.key = key;
          obj.value = item[key];
          this.oldMachTreatWays.push(obj);
        }
      });
    },
    // 旧机品类修改
    oldMachCategoryChange(e) {
      for (let item of this.oldMachCategorys) {
        if (e == item.key) {
          this.oldMachCategoryName = item.value;
          return;
        }
      }
    },
    // 旧机处理途径修改
    oldMachTreatWayChange(e) {
      this.oldMachTreatWay = e;
      for (let item of this.oldMachTreatWays) {
        if (e == item.key) {
          this.oldMachTreatWayName = item.value;
          return;
        }
      }
    }
  }
};
